import axios from 'axios';

export const saveAndGetUserData = (apiUrl,postdata) => {
    // const postData = {postdata};

    return axios.post(apiUrl+'saveUser', { data: postdata })
        .then(response => {
            const savedData = response.data;
            return {
                joinTelegramGroup: savedData.join_telegram_group,
                joinTelegramChannel: savedData.join_telegram_channel,
                joinInstagram: savedData.join_instagram,
                joinX: savedData.join_x
            };
        })
        .catch(error => {
            console.error('Error saving and getting data:', error);
            throw error;
        });
};
