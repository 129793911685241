import axios from 'axios';

export const sendData = (apiUrl,telegram_id, addToken) => {
    // alert('telegram_id=='+telegram_id);
    return axios.post(apiUrl+'addToken', {
        telegram_id: telegram_id,
        addToken: addToken
    }, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(response => {
            const data = response.data;
            const dataTokens = data.tokens;
            const dataUser = data.telegram_id;
            // console.log('Data received from API:', dataUser);
            // const useractivtime = dataUser.activity_time;
            // const userEmail = dataUser.email;
            // const userToken = dataUser.token;
            // const userWallet = dataUser.deposit_wallet;
            // alert('userTokens=' + dataTokens);
            // alert('userUser=' + dataUser);
            // return { ...data, useractivtime, userToken, userEmail, userWallet };
        })
        .catch(error => {
            // console.error('Error sending data:', error);
            throw error;
        });
};


// useEffect(() => {
//     // Load the session times from localStorage if it exists
//     const storedTimes = localStorage.getItem('T1m3uz3r');
//     if (storedTimes) {
//         setSessionTimes(JSON.parse(storedTimes));
//     }
//
//     const handleUnload = () => {
//         const currentTime = Date.now();
//         const activeTime = Math.floor((currentTime - lastActivityTime) / 1000 ); // Calculate active time in seconds
//         const newSessionTimes = [...sessionTimes, activeTime];
//         localStorage.setItem('T1m3uz3r', JSON.stringify(newSessionTimes));
//     };
//
//     window.addEventListener('beforeunload', handleUnload);
//
//     return () => {
//         window.removeEventListener('beforeunload', handleUnload);
//     };
// }, [lastActivityTime, sessionTimes]);
//
//
// useEffect(() => {
//     // Load the session times from localStorage if it exists
//     const storedTimes = localStorage.getItem('T1m3uz3r');
//     if (storedTimes) {
//         const sessionTimes = JSON.parse(storedTimes);
//         const totalSeconds = sessionTimes.reduce((acc, time) => acc + time, 0);
//         setTotalActiveTime(totalSeconds);
//     }
// }, []);