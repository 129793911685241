import React, {useState, useEffect, useRef} from 'react';
import {saveAndGetUserData} from './saveuser';
import axios from 'axios';
import {scroller} from 'react-scroll';
import {saveAs} from 'file-saver';
import lottie from 'lottie-web';
import {isMobile as detectMobile} from 'react-device-detect';
import {fetchAndSendData} from './getapi';
import {sendData} from './senddata';
import {resetLocalStorage} from './reset';
import moment from 'moment';
import './App.css';
import animationData from './images/Anime.json';
import modalIcon from './images/modal-icon.webp';
import coinLargeIcon from './images/coin-large-icon.webp';
import chargeIcon from './images/charge.webp';
import boostIcon from './images/boost.webp';
import exchangeIcon from './images/exchange-icon.webp';
import mineIcon from './images/mine-icon.webp';
import friendsIcon from './images/friends-icon.webp';
import earnIcon from './images/earn-icon.webp';
import instIcon from './images/instagram_1409946.webp';
import xtqrIcon from './images/img.webp';
import loadingImage from './images/tronscan.webp';
import setIcon from './images/settings.webp';
import telegramIcon from './images/telegram.webp';
import instagramIcon from './images/instagram.webp';
import xIcon from './images/x.webp';
import motivateIcon from './images/motivation.webp';
import enerrIcon from './images/energy.webp';
import tipsIcon from './images/tips.webp';
import Tel1Icon from './images/telegram1.webp';
import youtube from './images/video_15527679.webp';
import friends from './images/hug_7588406.webp';
import youtube1 from './images/youtube_4494487.webp';
import x1Icon from './images/x1.webp';
import Tel2Icon from './images/telegram2.webp';
import Tel4Icon from './images/telegram4.webp';
import cashdeskIcon from './images/cash-desk.webp';
import smallIcon from './images/small-coin.webp';
import clockIcon from './images/clock.webp';
import friIcon from './images/friends.webp';
import copy from './images/copy.webp'
import refresh from './images/arrows-rotate-solid.webp'
import qrbotIcon from './images/qr-code-bot.webp';
import comingsoon from './images/cs.webp'
import check from './images/check.webp'
import claw from './images/claw.webp'




function App() {
    // Function to get lion box image
    const getkingboxImage = (level) => {
        return process.env.PUBLIC_URL + `/king${level}.webp`;
    };


    const [addMaxCharge, setAddMaxCharge] = useState(0);
    const [i, setI] = useState(1);
    const [tdId, setTdId] = useState(null);
    const [referral, setReferral] = useState(null);
    // const [postData, setPostData] = useState(null);
    const [isPreLoading, setIsPreLoading] = useState(true);
    const [clicks, setClicks] = useState([]);
    const [count, setCount] = useState(0);
    const [newCount, setNewCount] = useState(0);
    const [visibleButtonCheck, setVisibleButtonCheck] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isBoostModalOpen, setIsBoostModalOpen] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [isEarnModalOpen, setIsEarnModalOpen] = useState(false);
    const [isFriendModalOpen, setIsFriendOpen] = useState(false);
    const [isInstagramModalOpen, setIsInstagramModalOpen] = useState(false);
    const [isTelegramModalOpen, setIsTelegramModalOpen] = useState(false);
    const [isyoutubeModalOpen, setIsyoutubeModalOpen] = useState(false);
    const [isfriendsinviteModalOpen, setIsfriendsinviteModalOpen] = useState(false);
    const [isTelegramModalOpen2, setIsTelegramModalOpen2] = useState(false);
    let [coins, setCoins] = useState(0);
    const [newCoins, setNewCoins] = useState(0);
    const [newDisplayedTokens, setNewDisplayedTokens] = useState(0);
    // const [incrementValue, setIncrementValue] = useState(0);
    const [animate, setAnimate] = useState(false);
    const [level, setLevel] = useState(0);
    const [progress, setProgress] = useState(0);
    const [chargeValue, setChargeValue] = useState(1000);
    const [maxChargeValue, setMaxChargeValue] = useState(1000);
    const [isUserActive, setIsUserActive] = useState(false);
    const [user, setUser] = useState({first_name: 'User', last_name: ''});
    const [boostAvailable, setBoostAvailable] = useState(6);
    const [boostCooldown, setBoostCooldown] = useState(0);
    const [boostLastUsed, setBoostLastUsed] = useState(null);
    const [isFullEnergyDisabled, setIsFullEnergyDisabled] = useState(false);
    const [lastResetTime, setLastResetTime] = useState(Date.now());
    const [countdown, setCountdown] = useState(3600); // 1 hour in seconds
    const [isTimerRunning, setIsTimerRunning] = useState(false); // New state for timer status
    const [userTokens, setUserTokens] = useState(5000); // Let's assume the user has 5000 tokens
    const [multitapLevel, setMultitapLevel] = useState(0); // Multitap level
    const [hasReceivedTelegramReward, setHasReceivedTelegramReward] = useState(false); // Status of receiving Telegram reward
    const [hasFriendsinviteReward, setHasFriendsinviteReward] = useState(0);
    const [hasReceivedTelegramReward2, setHasReceivedTelegramReward2] = useState(false); // Status of receiving Telegram reward
    const [hasClickedInstagramJoinButton, setHasClickedInstagramJoinButton] = useState(false);
    const [hasReceivedInstagramReward, setHasReceivedInstagramReward] = useState(false);
    const [hasReceivedYotubemReward, setHasReceivedyoutubeReward] = useState(false);
    const [isXModalOpen, setIsXModalOpen] = useState(false);
    const [hasClickedXJoinButton, setHasClickedXJoinButton] = useState(false);
    const [hasClickedyoutubeJoinButton, setHasClickedyoutubeJoinButton] = useState(false);
    const [hasReceivedXReward, setHasReceivedXReward] = useState(false);
    const [isDailyRewardModalOpen, setIsDailyRewardModalOpen] = useState(false);
    const [currentDay, setCurrentDay] = useState(1);
    const [lastClaimTime, setLastClaimTime] = useState(Date.now());
    const [claimedToday, setClaimedToday] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [joinedToYoutube, setJoinedToYoutube] = useState(null);
    const [invite5friend, setInvite5Friend] = useState(null);
    const [joinedGroupAt, setJoinedGroupAt] = useState(null);
    const [joinedToGroup, setJoinedToGroup] = useState(null);
    const [joinedToChannel, setJoinedToChannel] = useState(null);
    const [joinedToInstagram, setJoinedToInstagram] = useState(null);
    const [joinedToX, setJoinedToX] = useState(null);
    const [joinedChannelAt, setJoinedChannelAt] = useState(null);
    const [appActiveTime, setAppActiveTime] = useState(0); // total active time in app in seconds
    const [lastActivityTime, setLastActivityTime] = useState(Date.now());
    const [isMineModalOpen, setIsMineModalOpen] = useState(false);
    const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
    const [vibrationIntensity, setVibrationIntensity] = useState(20);
    const [walletAddress, setWalletAddress] = useState('');
    const [activeTab, setActiveTab] = useState('wallet');
    const [emailAddress, setEmailAddress] = useState('');
    const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState("");
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showTable, setShowTable] = useState(false);
    const [invitedUsers, setInvitedUsers] = useState([]);
    const [chargeSpeed, setChargeSpeed] = useState(1.2);
    const [isMobile, setIsMobile] = useState(true);
    const [lastSavedCoins, setLastSavedCoins] = useState(null);
    const [displayedTokens, setDisplayedTokens] = useState(0);
    const [receivedData, setReceivedData] = useState(null);
    const [telegramId, setTelegramId] = useState('');
    const [addToken, setAddToken] = useState('');
    const [isEmailSubmitted, setIsEmailSubmitted] = useState(false);
    const [isWalletSubmitted, setIsWalletSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [userEmail, setUserEmail] = useState(null);
    const [useractivtime, setUseractivtime] = useState(0);
    const [isDataSaved, setIsDataSaved] = useState(false);
    const [userToken, setUserToken] = useState(0);
    const [sessionTimes, setSessionTimes] = useState(0);
    const [totalActiveTime, setTotalActiveTime] = useState(0);
    const [showRedDot, setShowRedDot] = useState(false);
    const [sumFriends, setSumFriends] = useState({sum_freinds: '0'});
    const [angle, setAngle] = useState(0);
    const [spinning, setSpinning] = useState(false);
    const [selectedPrize, setSelectedPrize] = useState(null);
    const [isGameModalOpen, setIsGameModalOpen] = useState(false);
    const [isButtonVisible, setIsButtonVisible] = useState(true);
    const [isMulconfModalOpen, setIsMulconfModalOpen] = useState(false);
    const [isTurboDisabled, setIsTurboDisabled] = useState(false);
    const [isInfiniteCharge, setIsInfiniteCharge] = useState(false);
    const [earnPerClickBonus, setEarnPerClickBonus] = useState(0);
    const [turboCountdown, setTurboCountdown] = useState(30);
    const [isTurboActive, setIsTurboActive] = useState(false);
    const [currentImage, setCurrentImage] = useState(getkingboxImage());
    const [isTurbolion, setIsTurbolion] = useState(false);
    const [clawMarks, setClawMarks] = useState([]);
    const [remainingTime, setRemainingTime] = useState('');
    const [apiUrl, setApiUrl] = useState('https://nccbot.ncclionsarmy.com/api/v1/');
    // const [apiUrl, setApiUrl] = useState('https://nccbot.mywhc.ca/api/v1/');









    //bg animation //bg animation //bg animation //bg animation //bg animation //bg animation //bg animation //bg animation
    //bg animation //bg animation //bg animation //bg animation //bg animation //bg animation //bg animation //bg animation

    const animationContainer = useRef(null);

    useEffect(() => {
        let animation;
        if (animationContainer.current) {
            animation = lottie.loadAnimation({
                container: animationContainer.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: animationData,
            });
        }

        return () => {
            if (animation) {
                animation.destroy();
            }
        };
    }, []);


    const handleClick2 = (e) => {
        e.preventDefault();
        const incrementValue = 1; // مقدار افزایش در هر کلیک
        const centerX = e.clientX;
        const centerY = e.clientY;

        const newClick = {
            id: Date.now(),
            x: centerX,
            y: centerY,
            value: incrementValue
        };

        setClicks([...clicks, newClick]);
        setAnimate(true);
        setTimeout(() => setAnimate(false), 500);
    };


    //bg animation //bg animation //bg animation //bg animation //bg animation //bg animation //bg animation //bg animation //bg animation
    //bg animation //bg animation //bg animation //bg animation //bg animation //bg animation //bg animation //bg animation //bg animation




    //Cards //Cards //Cards //Cards //Cards //Cards //Cards //Cards //Cards //Cards //Cards //Cards //Cards //Cards //Cards //Cards
    //Cards //Cards //Cards //Cards //Cards //Cards //Cards //Cards //Cards //Cards //Cards //Cards //Cards //Cards //Cards //Cards
    const renderCard = (title, profit, cost, isLocked, levelRequirement) => (
        <div className="card">
            <div className="card-content">
                <div className="card-title">{title}</div>
                <div className="card-info">Profit per hour: {profit}</div>
                <div className="card-info">Cost: {cost}</div>
                {isLocked && <div className="card-info locked">Locked (Requires {levelRequirement})</div>}
            </div>
        </div>
    );

    //Cards //Cards //Cards //Cards //Cards //Cards //Cards //Cards //Cards //Cards //Cards //Cards //Cards //Cards //Cards //Cards
    //Cards //Cards //Cards //Cards //Cards //Cards //Cards //Cards //Cards //Cards //Cards //Cards //Cards //Cards //Cards //Cards




    const topTouchAreaRef = useRef(null);

    useEffect(() => {
        const preventDefault = (e) => {
            e.preventDefault();
        };

        const preventWindowMinimize = (e) => {
            if (e.touches && e.touches.length > 1) {
                e.preventDefault();
            }
        };

        document.addEventListener('touchstart', preventWindowMinimize, {passive: false});
        document.addEventListener('gesturestart', preventDefault);
        document.addEventListener('gesturechange', preventDefault);
        document.addEventListener('gestureend', preventDefault);

        return () => {
            document.removeEventListener('touchstart', preventWindowMinimize);
            document.removeEventListener('gesturestart', preventDefault);
            document.removeEventListener('gesturechange', preventDefault);
            document.removeEventListener('gestureend', preventDefault);
        };
    }, []);




    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const sendCoinDisplayData = async (tokens) => {
        if (telegramId) {
            // alert('telegramId=='+telegramId);
            try {
                const response = await sendData(apiUrl, telegramId, tokens);
                if (response.success) {
                    setIsDataSaved(true);
                }
            } catch (error) {
            }
        }
    };

    /////////------------------------// let dataUser;


    useEffect(() => {
        const fetchData = async () => {
            if (window.Telegram && window.Telegram.WebApp) {
                const teleWebapp = window.Telegram.WebApp;
                const initData = teleWebapp.initData;
                console.log("initData:", initData); // برای دیباگ کردن
                if (!initData) {
                    alert('initData is missing');
                    return;
                }
                const params = new URLSearchParams(initData);
                const user = JSON.parse(params.get('user'));
                const queryId = params.get('query_id');
                const hash = params.get('hash');
                const authDate = params.get('auth_date');
                console.log("User Data:", user); // برای مشاهده اطلاعات کاربر
                if (user && queryId && hash && authDate) {
                    setTdId(user.id);
                    const userSetting = JSON.parse(localStorage.getItem('userSetting'));
                    const lastTime = JSON.parse(localStorage.getItem('lastTime')) || Date.now();
                    console.log("User Setting:", userSetting);
                    console.log("Last Time:", lastTime);
                    localStorage.setItem('lastTime', JSON.stringify(lastTime || Date.now()));
                    if (userSetting) {
                        setChargeValue(userSetting.chargevalue);
                        localStorage.setItem('userSetting', JSON.stringify({ chargevalue: chargeValue }));
                    }

                    const lastSpinData = JSON.parse(localStorage.getItem('lastSpinData'));
                    const currentTime = Date.now();

                    if (!lastSpinData || (currentTime - lastSpinData.time) > 24 * 60 * 60 * 1000) {
                        setIsGameModalOpen(true);
                    }

                    const postData = {
                        initData: initData,
                        query_id: queryId,
                        hash: hash,
                        user: JSON.stringify(user),
                        auth_date: authDate,
                    };
                    try {
                        const response = await axios.post(apiUrl + 'checkUser', postData);
                        console.log("Response Data:", response.data); // مشاهده‌ی پاسخ API
                        const { user: dataUser } = response.data;
                        if (dataUser) {
                            localStorage.setItem('Qtvbv1v3', JSON.stringify(dataUser));
                            const savedData = JSON.parse(localStorage.getItem('Qtvbv1v3'));
                            setIsPreLoading(false);
                            setTelegramId(savedData.telegram_id);

                            if (savedData && savedData.tokens) {
                                setCoins(savedData.tokens);
                                setDisplayedTokens(savedData.tokens);
                                setClicks([]);

                                axios.post(apiUrl + 'getSubsets', {
                                    telegram_id: savedData.telegram_id
                                }).then(response => {
                                    if (response.status === 200) {
                                        const dataUsers = response.data;
                                        const users = dataUsers.users;
                                        const user = dataUsers.user;
                                        setInvitedUsers(users);
                                    }
                                }).catch(error => {
                                    // alert('Error sending get Subsets:' + error);
                                });
                            }

                            if (savedData) {
                                setCoins(savedData.tokens);
                                setUserEmail(savedData.email);
                                setWalletAddress(savedData.deposit_wallet);
                                setLevel(Number(savedData.level_user));
                                setAddMaxCharge(Number(level) * 500);
                                setMaxChargeValue(prevCHv => (1000 + (Number(savedData.level_user) * 500)));
                                if (Number(savedData.level_user) > 1) {
                                    let result = Math.pow(1.2, Number(savedData.level_user) - 1);
                                    setChargeSpeed(result);
                                }
                                setProgress(savedData.progress);
                                setReferral(savedData.refral_code);
                                setJoinedToGroup(savedData.join_telegram_group);
                                setJoinedToChannel(savedData.join_telegram_channel);
                                setJoinedToInstagram(savedData.join_instagram);
                                setJoinedToX(savedData.join_x);
                                setJoinedToYoutube(savedData.join_youtube);
                                setHasClickedyoutubeJoinButton(savedData.join_youtube);
                                setHasFriendsinviteReward((Math.floor(Number(savedData.sum_friends) / 5) - Number(savedData.invited_friends)));
                                setLastActivityTime(savedData.activity_time);
                                setMultitapLevel(Number(savedData.multi_tap_level));
                                localStorage.setItem('T1m3uz3r', JSON.stringify(savedData.activity_time));

                                let chckTime = Math.round((Number(Date.now()) - Number(lastTime)) / 1000) + Number(userSetting.chargevalue);
                                let maxCHV = Number(1000 + (Number(savedData.level_user) * 500));
                                let newcharVal = Number(chargeValue);

                                if (chckTime > maxCHV)
                                    newcharVal = 1000 + (Number(savedData.level_user) * 500);
                                else
                                    newcharVal = userSetting.chargevalue + Math.round((Number(Date.now()) - Number(lastTime)) / 1000);

                                setChargeValue(newcharVal);
                                localStorage.setItem('lastTime', JSON.stringify(Date.now()));
                                localStorage.setItem('userSetting', JSON.stringify({ chargevalue: newcharVal }));


                                let tstamp = savedData.full_energy;
                                let tnow = new Date().getTime();
                                let tDiv = Math.round(Number(tnow - tstamp) / 1000);
                                const startOfDay = moment().startOf('day').valueOf();
                                const endOfDay = moment().endOf('day').valueOf();
                                if (tstamp >= startOfDay && tstamp <= endOfDay) {
                                    setBoostAvailable(Number(savedData.boost_full_energy));
                                }

                                if ((Number(tnow) - (3600 * 1000)) < Number(tstamp)) {
                                    setCountdown(3600 - tDiv);
                                    setIsFullEnergyDisabled(true);
                                    setIsTimerRunning(true);
                                }

                                // تنظیم وضعیت showRedDot بر اساس داده‌های دریافتی
                                if (savedData.join_youtube !== 1 && savedData.join_telegram_group !== 1 && savedData.join_telegram_channel !== 1 && savedData.join_instagram !== 1 && savedData.join_x !== 1) {
                                    setShowRedDot(true);
                                } else {
                                    setShowRedDot(false);
                                }
                            }
                        } else {
                            alert('No user data received from the response');
                        }
                    } catch (error) {
                        alert('Missing required parameters' + error);
                    }
                } else {
                    alert('Missing required parameters');
                }
            } else {
                alert('Telegram WebApp is not defined');
            }
        };
        fetchData();
    }, []);


    // spin game // spin game // spin game // spin game // spin game // spin game // spin game // spin game // spin game // spin game
    // spin game // spin game // spin game // spin game // spin game // spin game // spin game // spin game // spin game // spin game
    const prizes = ["0", "70,000", "1,000", "50", "100,000", "7,500", "18,000", "35,000", "250"];
    const probabilities = {
        "100,000": 1, // 1%
        "35,000": 2,  // 2%
        "18,000": 2,  // 2%
        "default": 95 // 95% به صورت مساوی بین بقیه تقسیم می‌شود
    };

    const spinWheel = () => {
        console.log("Spin Wheel function called");
        setIsButtonVisible(false);

        const lastSpinData = JSON.parse(localStorage.getItem('lastSpinData'));
        const currentTime = Date.now();

        if (!lastSpinData) {
            // اگر داده‌ای موجود نیست، چرخش را اجرا کنید و اطلاعات را در لوکال استوریج ذخیره کنید
            localStorage.setItem('lastSpinData', JSON.stringify({ time: currentTime, isGameModalOpen: true }));
            startSpinWheel(); // اجرای فانکشن چرخش چرخ
        } else if ((currentTime - lastSpinData.time) > 24 * 60 * 60 * 1000) {
            // اگر داده‌های قبلی قدیمی هستند، چرخش را اجرا کنید و اطلاعات جدید را ذخیره کنید
            startSpinWheel(); // اجرای فانکشن چرخش چرخ
            localStorage.setItem('lastSpinData', JSON.stringify({ time: currentTime, isGameModalOpen: true }));
        } else {
            // در غیر این صورت، چرخش انجام نشود
            console.log("You need to wait before spinning again.");
        }
    };

    const startSpinWheel = () => {
        if (!spinning) {
            setSpinning(true);
            const prizes = ["0", "70,000", "1,000", "50", "100,000", "7,500", "18,000", "35,000", "250"];
            const segmentAngle = 360 / prizes.length;

            const selectPrize = () => {
                const random = Math.random() * 100;
                let cumulativeProbability = 0;
                const probabilities = {
                    "100,000": 1,
                    "35,000": 2,
                    "18,000": 2,
                    "default": 95
                };

                for (let prize in probabilities) {
                    if (prize !== "default") {
                        cumulativeProbability += probabilities[prize];
                        if (random < cumulativeProbability) {
                            return prize;
                        }
                    }
                }

                const remainingPrizes = prizes.filter(prize => !["100,000", "35,000", "18,000"].includes(prize));
                const remainingProbability = probabilities["default"] / remainingPrizes.length;

                for (let prize of remainingPrizes) {
                    cumulativeProbability += remainingProbability;
                    if (random < cumulativeProbability) {
                        return prize;
                    }
                }

                return remainingPrizes[0];
            };

            const selectedPrize = selectPrize();
            const selectedPrizeIndex = prizes.indexOf(selectedPrize);
            const targetAngle = (360 - (selectedPrizeIndex * segmentAngle + segmentAngle / 2) - 65) % 360;
            const randomRotations = Math.floor(Math.random() * 8) + 5;
            const fullRotations = randomRotations * 360;
            const finalAngle = fullRotations + targetAngle;

            setAngle(finalAngle);

            setTimeout(() => {
                setSelectedPrize(selectedPrize);
                setSpinning(false);

                const prizeAmount = parseInt(selectedPrize.replace(/,/g, ''), 10);
                let newCoins = Number(coins);
                newCoins += prizeAmount;

                setCoins(newCoins);
                setDisplayedTokens(newCoins);

                const savedData = JSON.parse(localStorage.getItem('Qtvbv1v3')) || {};
                localStorage.setItem('Qtvbv1v3', JSON.stringify({
                    ...savedData,
                    tokens: newCoins
                }));

                // بروزرسانی lastSpinData با مقدار جدید
                localStorage.setItem('lastSpinData', JSON.stringify({ time: Date.now(), isGameModalOpen: false }));

                // اجرای انیمیشن createCoinRain در صورتی که جایزه برابر با 0 نباشد
                if (prizeAmount > 0) {
                    createCoinRain();
                }

                setTimeout(() => {
                    setIsButtonVisible(true);
                    setIsGameModalOpen(false);
                }, 3000);

            }, 7000);
        }
    };

// فراخوانی فانکشن در صورت لزوم
    useEffect(() => {
        if (receivedData) {
            spinWheel();
        }
    }, [receivedData]);




    // spin game // spin game // spin game // spin game // spin game // spin game // spin game // spin game // spin game // spin game
    // spin game // spin game // spin game // spin game // spin game // spin game // spin game // spin game // spin game // spin game


    useEffect(() => {
        localStorage.setItem('userSetting', JSON.stringify({chargevalue: chargeValue}));
    }, [chargeValue]);



    useEffect(() => {
        let newSessionTimes = JSON.parse(localStorage.getItem('T1m3uz3r')) || 0;
        setTotalActiveTime(newSessionTimes);
        const savedData = JSON.parse(localStorage.getItem('Qtvbv1v3'));
        setSessionTimes(Number(newSessionTimes));

        if (newSessionTimes >= 0) {
            const interval = setInterval(() => {
                setSessionTimes(prevSessionTimes => {
                    const newSessionTimes = prevSessionTimes + 1;
                    localStorage.setItem('T1m3uz3r', JSON.stringify(newSessionTimes));
                    if (newSessionTimes % 60 === 0) {
                        const postData = {
                            telegram_id: savedData.telegram_id,
                            activity_time: newSessionTimes
                        };
                        saveAndGetUserData(apiUrl, postData);
                    }
                    setTotalActiveTime(prevTotalActiveTime => prevTotalActiveTime + 1);
                    return newSessionTimes;
                });
            }, 1000); // 1000 milliseconds = 1 second

            return () => clearInterval(interval);
        }
    }, []);


    //user time activity //user time activity //user time activity //user time activity //user time activity //user time activity
    //user time activity //user time activity //user time activity //user time activity //user time activity //user time activity

    useEffect(() => {
        if (receivedData) {
            let start = displayedTokens;
            const end = receivedData.tokens;
            if (start === end) return;
            let startTime = null;
            const duration = 1000;
            const step = (timestamp) => {
                if (!startTime) startTime = timestamp;
                const progress = Math.min((timestamp - startTime) / duration, 1);
                setDisplayedTokens(Math.floor(progress * (end - start) + start));
                if (progress < 1) {
                    window.requestAnimationFrame(step);
                }
            };

            window.requestAnimationFrame(step);
        }
    }, [receivedData]);

    //multituch zoom //multituch zoom //multituch zoom //multituch zoom //multituch zoom //multituch zoom //multituch zoom //multituch zoom
    //multituch zoom //multituch zoom //multituch zoom //multituch zoom //multituch zoom //multituch zoom //multituch zoom //multituch zoom

    useEffect(() => {
        const handleTouchMove = (event) => {
            if (event.scale !== 1) {
                // event.preventDefault();
            }
        };

        document.addEventListener('touchmove', handleTouchMove, {passive: false});

        return () => {
            document.removeEventListener('touchmove', handleTouchMove);
        };
    }, []);

    //multituch zoom //multituch zoom //multituch zoom //multituch zoom //multituch zoom //multituch zoom //multituch zoom //multituch zoom
    //multituch zoom //multituch zoom //multituch zoom //multituch zoom //multituch zoom //multituch zoom //multituch zoom //multituch zoom


    const handleCopyClick = () => {
        if (referral) {
            const telegramLink = `https://t.me/Ncc_Lions_Army_bot/?start=${referral}`;
            navigator.clipboard.writeText(telegramLink).then(() => {
                setSuccessMessage("link saved to the clipboard");
                setShowSuccessModal(true);
            }).catch(err => {
                setSuccessMessage("Failed to copy text: ", err);
                setShowSuccessModal(true);
            });
        } else {
            setSuccessMessage("Referral code is not available");
            setShowSuccessModal(true);
        }
    };


    const saveToLocalStorage = (key, value) => {
        localStorage.setItem(key, JSON.stringify(value));
    };

    const loadFromLocalStorage = (key) => {
        const savedData = localStorage.getItem(key);
        return savedData ? JSON.parse(savedData) : null;
    };


    useEffect(() => {
        const handleBeforeUnload = () => {
            const savedData = loadFromLocalStorage('Qtvbv1v3');
            const newData = {
                ...savedData,
            };
            saveToLocalStorage('Qtvbv1v3', newData);
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);


    const handleEmailChange = (event) => {
        setEmailAddress(event.target.value);
    };


    useEffect(() => {
        const userAgent = navigator.userAgent;

        // List of desktop browsers
        const desktopBrowsers = /TelegramDesktop|Windows|Macintosh|Linux|Chrome|Firefox|Safari|Edge|Opera|Trident|MSIE|Brave/i;

        // List of mobile devices
        const mobileDevices = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|Mobile Safari|Silk|Samsung|Nexus|Pixel|LG|HTC|Huawei|Motorola|OnePlus|Sony|note10 light/i;

        const isDesktop = desktopBrowsers.test(userAgent);
        const isMobile = mobileDevices.test(userAgent);

        setIsMobile(isMobile && !isDesktop);
    }, []);


    useEffect(() => {
        setIsMobile(detectMobile);
    }, []);

    const coinsToLevelUpArray = [5000, 25000, 125000, 400000, 2000000, 15000000, 60000000, 200000000, 500000000, 1000000000];

    const confirmModalRef = useRef(null);

    useEffect(() => {
        const interval = setInterval(() => {
            const now = Date.now();
            const twentyFourHours = 24 * 60 * 60 * 1000;

            if (now - lastClaimTime >= twentyFourHours) {
                setClaimedToday(false);
                setCurrentDay((prevDay) => (prevDay < 10 ? prevDay + 1 : 1));
                setLastClaimTime(now);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [lastClaimTime]);

    useEffect(() => {
        localStorage.setItem('userSetting', JSON.stringify({chargevalue: chargeValue}));
        const interval = setInterval(() => {

            if (!isUserActive) {
                setChargeValue(prevCharge => Math.min(Math.round(prevCharge + chargeSpeed), maxChargeValue));
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [isUserActive, maxChargeValue, chargeSpeed]);

    useEffect(() => {
        localStorage.setItem('userSetting', JSON.stringify({chargevalue: chargeValue}));

        const timeout = setTimeout(() => {
            setIsUserActive(false);
        }, 100);

        return () => clearTimeout(timeout);
    }, [count]);

    useEffect(() => {
        const savedVibrationIntensity = localStorage.getItem('vibrationIntensity');
        if (savedVibrationIntensity) {
            setVibrationIntensity(Number(savedVibrationIntensity));
        }
    }, []);


    useEffect(() => {
        const checkTelegram = () => {
            if (typeof window.Telegram !== "undefined" && typeof window.Telegram.WebApp !== "undefined") {
                window.Telegram.WebApp.expand();
                const user = window.Telegram.WebApp.initDataUnsafe.user;
                setUser(user || {first_name: '', last_name: ''});
            }
        };
        const intervalId = setInterval(checkTelegram, 500);

        // Stop checking after 5 seconds
        setTimeout(() => {
            clearInterval(intervalId);
        }, 5000);

        return () => clearInterval(intervalId);
    }, []);


    useEffect(() => {
        if (boostCooldown > 0) {
            const timer = setInterval(() => {
                setBoostCooldown(prevCooldown => {
                    if (prevCooldown <= 1) {
                        clearInterval(timer);
                        return 0;
                    }
                    return prevCooldown - 1;
                });
            }, 60000);
        }
    }, [boostCooldown]);

    useEffect(() => {
        if (boostLastUsed && new Date() - boostLastUsed >= 86400000) {
            setBoostAvailable(6);
            setBoostLastUsed(null);
        }
    }, [boostLastUsed]);

    useEffect(() => {
        if (isFullEnergyDisabled && isTimerRunning) {
            const timer = setInterval(() => {
                setCountdown(prevCountdown => {
                    if (prevCountdown <= 1) {
                        clearInterval(timer);
                        setIsFullEnergyDisabled(false);
                        setIsTimerRunning(false);
                        return 3600;
                    }
                    return Number(prevCountdown) - 1;
                });
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [isFullEnergyDisabled, isTimerRunning]);

    const checkAnyDayFullEnergy = () => {
        const savedData = loadFromLocalStorage('Qtvbv1v3') || {};
        const now = Date.now();
        if (Number(now - lastResetTime) >= 86400000) {
            setBoostAvailable(6);
            setLastResetTime(now);
            const postData = {
                telegram_id: savedData.telegram_id,
                full_energy: 0,
                boost_full_energy: Number(boostAvailable)
            };
            saveAndGetUserData(apiUrl, postData);
        }
    };

    useEffect(() => {
        if (!isTimerRunning) {
            setIsFullEnergyDisabled(chargeValue >= maxChargeValue);
        }
    }, [chargeValue, maxChargeValue, isTimerRunning]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (confirmModalRef.current && !confirmModalRef.current.contains(event.target)) {
                setIsConfirmModalOpen(false);
                setIsMulconfModalOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setAppActiveTime(prevTime => prevTime + 1);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        // let i=1;
        const checkInactivity = setInterval(() => {
            // const now = Date.now();
            if (i >= 3 * 60 * 1000) { // 10 minutes
                if (window.Telegram && window.Telegram.WebApp) {
                    // alert('i==' + i);
                    localStorage.setItem('userSetting', JSON.stringify({chargevalue: chargeValue}));

                    window.Telegram.WebApp.close(function () {
                        // alert('man baste shoda');
                    });
                }
            }
            setI(prevI => prevI + 1);
        }, 1000); // Check every 1 minute
    }, []);


    const handleClick = (e) => {
        e.preventDefault();
        const savedData = loadFromLocalStorage('Qtvbv1v3');
        localStorage.setItem('lastTime', JSON.stringify(Date.now()));

        // محاسبه‌ی مقدار افزایش یافته با توجه به bonus
        const incrementValue = Number(level) + Number(multitapLevel) + 1 + earnPerClickBonus;

        if (chargeValue < incrementValue || chargeValue <= 0) {
            return;
        }

        setI(1);
        setIsUserActive(true);
        setAnimate(true);
        setTimeout(() => setAnimate(false), 500);

        if (navigator.vibrate) {
            navigator.vibrate(vibrationIntensity);
        }

        const touches = Array.from(e.touches ? e.touches : [e]);
        let newClicks = [...clicks];
        let newCount = count;
        let newCoins = Number(coins);
        let newDisplayedTokens = Number(displayedTokens);

        touches.forEach(touch => {
            const centerX = touch.clientX;
            const centerY = touch.clientY;



            const newClick = {
                id: newCount,
                x: centerX,
                y: centerY,
                value: incrementValue
            };
            newClicks.push(newClick);
            newCount += 1;
            newCoins += incrementValue;
            newDisplayedTokens += incrementValue;

            if (isTurboActive) {
                const newClawMark = {
                    id: Date.now(),
                    x: centerX,
                    y: centerY,
                    fadeOut: false // وضعیت اولیه محو شدن
                };
                setClawMarks(prevMarks => [...prevMarks, newClawMark]);

                // فعال کردن محو شدن بعد از یک تاخیر
                setTimeout(() => {
                    setClawMarks(prevMarks =>
                        prevMarks.map(mark =>
                            mark.id === newClawMark.id ? { ...mark, fadeOut: true } : mark
                        )
                    );
                }, 100); // تاخیر کوتاه برای اجرای transition
            }
        });

        setClicks(newClicks);
        setCount(newCount);
        setCoins(newCoins);
        setDisplayedTokens(newDisplayedTokens);

        let updatedLevel = level;
        let remainingCoins = newCoins;

        while (updatedLevel < coinsToLevelUpArray.length && remainingCoins >= coinsToLevelUpArray[updatedLevel]) {
            remainingCoins -= coinsToLevelUpArray[updatedLevel];
            updatedLevel += 1;
            setChargeValue(prevCharge => prevCharge + 500);
            setMaxChargeValue(prevMaxCharge => prevMaxCharge + 500);
            setChargeSpeed(prevSpeed => prevSpeed * 1.25); // Increase charge speed
        }

        setLevel(updatedLevel);
        if (updatedLevel < coinsToLevelUpArray.length) {
            setProgress((remainingCoins / coinsToLevelUpArray[updatedLevel]) * 100);
        } else {
            setProgress(100);
        }

        // کاهش مقدار chargeValue به تعداد کل لمس‌ها
        const totalReduction = incrementValue;
        setChargeValue(prevCharge => Math.max(0, prevCharge - totalReduction));
        localStorage.setItem('userSetting', JSON.stringify({chargevalue: chargeValue}));

        setTimeout(() => {
            setClicks((clicks) => clicks.filter(click => !newClicks.includes(click)));
        }, 1000);

        // Update the displayed tokens to trigger animation
        setDisplayedTokens(newDisplayedTokens);
        setReceivedData(prevData => ({...prevData, tokens: newDisplayedTokens}));
        saveToLocalStorage('Qtvbv1v3', {
            ...savedData,
            clicks: [],
            coins: newCoins,
            level: updatedLevel,
            progress: (remainingCoins / coinsToLevelUpArray[updatedLevel]) * 100,
        });
        const postData = {
            telegram_id: savedData.telegram_id,
            level_user: updatedLevel,
            progress: (remainingCoins / coinsToLevelUpArray[updatedLevel]) * 100,
        };
        saveAndGetUserData(apiUrl, postData);
        sendCoinDisplayData(newDisplayedTokens);
    };







    const handleMultitapClickWithModal = () => {
        setIsMulconfModalOpen(true);
        setIsModalOpen(false);
        setIsConfirmModalOpen(false);
        setIsSettingsModalOpen(false);
        setIsFriendOpen(false);
        setIsEarnModalOpen(false);
    };

    const toggleMineModal = () => {
        setIsMineModalOpen(!isMineModalOpen);
        setIsModalOpen(false);
        setIsBoostModalOpen(false);
        setIsConfirmModalOpen(false);
        setIsSettingsModalOpen(false);
        setIsFriendOpen(false);
        setIsEarnModalOpen(false);
    };

    const toggleDailyRewardModal = () => {
        setIsDailyRewardModalOpen(!isDailyRewardModalOpen);
    };

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const toggleBoostModal = () => {
        setIsBoostModalOpen(!isBoostModalOpen);
    };

    const togglefriendModal = () => {
        setIsFriendOpen(!isFriendModalOpen);
        setIsModalOpen(false);
        setIsBoostModalOpen(false);
        setIsConfirmModalOpen(false);
        setIsMineModalOpen(false);
        setIsSettingsModalOpen(false);
        setIsEarnModalOpen(false);
    };

    const toggleEarnModal = () => {
        setIsEarnModalOpen(!isEarnModalOpen);
        setIsModalOpen(false);
        setIsBoostModalOpen(false);
        setIsConfirmModalOpen(false);
        setIsMineModalOpen(false);
        setIsSettingsModalOpen(false);
        setIsFriendOpen(false);
    };

    const toggleSettingsModal = () => {
        setIsSettingsModalOpen(!isSettingsModalOpen);
        setIsXModalOpen(false);
        setIsMineModalOpen(false);
        setIsEarnModalOpen(false);
        setIsFriendOpen(false);
        setIsModalOpen(false);
        setIsBoostModalOpen(false);
        setIsConfirmModalOpen(false);
    };

    const toggleInstagramModal = () => {
        setIsInstagramModalOpen(!isInstagramModalOpen);
    };

    const togglexModal = () => {
        setIsXModalOpen(!isXModalOpen);
    };

    const toggleTelegramModal = () => {
        setIsTelegramModalOpen(!isTelegramModalOpen);
        setIsInstagramModalOpen(false);
        setIsTelegramModalOpen2(false);
        setIsXModalOpen(false);
    };
    const toggleyoutubeModal = () => {
        setIsyoutubeModalOpen(!isyoutubeModalOpen);
        setIsInstagramModalOpen(false);
        setIsTelegramModalOpen2(false);
        setIsXModalOpen(false);
    };

    const toggle5friendModal = () => {
        setIsfriendsinviteModalOpen(!isfriendsinviteModalOpen);
        setIsInstagramModalOpen(false);
        setIsTelegramModalOpen2(false);
        setIsXModalOpen(false);
    };

    const toggleTelegramModal2 = () => {
        setIsTelegramModalOpen2(!isTelegramModalOpen2);
        setIsInstagramModalOpen(false);
        setIsTelegramModalOpen(false);
        setIsXModalOpen(false);
    };

    const toggleRefreshfriend = () => {
        let savedData = loadFromLocalStorage('Qtvbv1v3') || {};

        setVisibleButtonCheck(true);
        axios.post(apiUrl + 'getSubsets', {
            telegram_id: savedData.telegram_id
        }).then(response => {

            if (response.status === 200) {
                const dataUsers = response.data;
                // dataUsers.users = undefined;
                const users = dataUsers.users;
                const user = dataUsers.user;
                setInvitedUsers(users);
                setVisibleButtonCheck(false);
                saveToLocalStorage('Qtvbv1v3', {
                    ...savedData,
                    invited_friends: user.invited_friends,
                    sum_friends: user.sum_friends,
                });
                setHasFriendsinviteReward((Math.floor(Number(user.sum_friends) / 5) - Number(user.invited_friends)));
                return users;
            } else {
                return response;
            }
        }).catch(error => {
            // alert('Error sending get Subsets:' + error);
        });

    };

    const generateInviteLink = () => {
        const userId = user.id;
        const inviteToken = btoa(userId);
        return `https://t.me/Ncc_Lions_Army_bot/=${inviteToken}`;
    };

    const openyoutubeLink = () => {
        window.open('https://www.youtube.com/@netcoincapital.official1450', '_blank');
        setHasClickedyoutubeJoinButton(true);
    };

    const openTelegramLink = () => {
        window.open('https://t.me/netcoincapital_community', '_blank');
    };

    const openTelegramLink2 = () => {
        window.open('https://t.me/Netcoincapital_ncc', '_blank');
    };

    const openInstagramLink = () => {
        window.open('https://www.instagram.com/netcoincapitalofficial/', '_blank');
        setHasClickedInstagramJoinButton(true);
    };

    const openXLink = () => {
        window.open('https://twitter.com/ncctoken', '_blank');
        setHasClickedXJoinButton(true);
    };

    const createCoinRain = () => {
        const coinContainer = document.createElement('div');
        coinContainer.style.position = 'fixed';
        coinContainer.style.top = '0';
        coinContainer.style.left = '0';
        coinContainer.style.width = '100%';
        coinContainer.style.height = '100%';
        coinContainer.style.pointerEvents = 'none';
        coinContainer.style.zIndex = '1000';

        for (let i = 0; i < 100; i++) {
            const coin = document.createElement('img');
            coin.src = coinLargeIcon; // Use the imported image
            coin.classList.add('coin');
            coin.style.left = `${Math.random() * 100}%`;
            coin.style.animationDelay = `${Math.random() * 2}s`;
            coinContainer.appendChild(coin);
        }

        document.body.appendChild(coinContainer);

        setTimeout(() => {
            document.body.removeChild(coinContainer);
        }, 3000); // Animation duration (3 seconds)
    };


    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };


    const handleSubmit = async () => {
        // setPostData({telegram_id: telegramId});
        const savedData = loadFromLocalStorage('Qtvbv1v3') || {};

        let postData = {
            telegram_id: savedData.telegram_id
        };

        if (walletAddress) {
            postData = {...postData, deposit_wallet: walletAddress};
        }

        if (emailAddress) {
            postData = {...postData, email: emailAddress};
        }

        if (walletAddress || emailAddress) {
            try {
                await saveAndGetUserData(apiUrl, postData);
                setIsSubmitted(true);

                // Set individual submission states
                if (walletAddress) {
                    setIsWalletSubmitted(true);
                }
                if (emailAddress) {
                    setIsEmailSubmitted(true);
                }
            } catch (error) {
                // Handle error
            }
        } else {
            // Handle case where no input is provided
        }
    };


    const closeAllModals = () => {
        setIsModalOpen(false);
        setIsBoostModalOpen(false);
        setIsConfirmModalOpen(false);
        setIsEarnModalOpen(false);
        setIsInstagramModalOpen(false);
        setIsTelegramModalOpen(false);
        setIsTelegramModalOpen2(false);
        setIsMineModalOpen(false);
        setIsSettingsModalOpen(false);
        setIsXModalOpen(false);
        setIsDailyRewardModalOpen(false);
        setIsFriendOpen(false);
    };

    const handleVibrationChange = (event) => {
        setVibrationIntensity(event.target.value);
    };

    const handleWalletAddressChange = (event) => {
        setWalletAddress(event.target.value);
    };

    const handleClaimReward = () => {
        const now = Date.now();

        if (claimedToday) {
            setSuccessMessage("You have already claimed your reward for today. Please wait 24 hours to claim the next reward.");
            setShowSuccessModal(true);
            return;
        }

        const reward = currentDay === 1 ? 500 : currentDay === 2 ? 1000 : currentDay === 3 ? 2500 : currentDay === 4 ? 5000 : currentDay === 5 ? 15000 : currentDay === 6 ? 25000 : currentDay === 7 ? 100000 : currentDay === 8 ? 500000 : currentDay === 9 ? 1000000 : 5000000;

        setCoins(coins + reward);
        setLastClaimTime(now);
        setClaimedToday(true);
    };

    const handleCheckyoutubeMembership = async () => {
        if (!hasClickedyoutubeJoinButton) {
            setSuccessMessage("You need to susbscribe the youtube first.");
            setShowSuccessModal(true);
            return;
        }
        if (!joinedToYoutube)
            try {

                let newCoins = Number(coins);
                let newDisplayedTokens = Number(displayedTokens);
                newCoins = Number(newCoins + 75000);
                newDisplayedTokens = Number(newDisplayedTokens + 75000);
                setCoins(Number(newCoins));
                setDisplayedTokens(newDisplayedTokens);
                setReceivedData(prevData => ({...prevData, tokens: newDisplayedTokens}));
                const savedData = loadFromLocalStorage('Qtvbv1v3') || {};
                saveToLocalStorage('Qtvbv1v3', {
                    ...savedData,
                    tokens: newCoins
                });
                sendCoinDisplayData(newCoins);
                setHasReceivedyoutubeReward(true);
                createCoinRain();
                setSuccessMessage("You have successfully checked the youtube Channel. +75,000 coins added!");
                setShowSuccessModal(true);

                const postData = {
                    telegram_id: savedData.telegram_id,
                    join_youtube: 1
                };
                await saveAndGetUserData(apiUrl, postData);
                setJoinedToYoutube(1);
                saveToLocalStorage('Qtvbv1v3', {
                    ...savedData,
                    joinedToYoutube: 1
                });
            } catch (error) {
                setErrorMessage("An error occurred while checking youtube subscription status.");
                setShowErrorModal(true);
            }
    };


    const handleCheckfriendsinviteMembership = async () => {
        let savedData = loadFromLocalStorage('Qtvbv1v3') || {};
        let divTo5=Math.floor(Number(savedData.sum_friends) / 5);
        let pasokh=divTo5 - Number(savedData.invited_friends);
        setHasFriendsinviteReward(pasokh);
        // if (joinedToYoutube)
        try {
            let newCoins = Number(coins);
            let newDisplayedTokens = Number(displayedTokens);
            let newSubset = Number(savedData.sum_friends) || 0;
            if (newSubset > 0 && Number(savedData.invited_friends) >= 0) {
                let old5friends = Number(savedData.invited_friends);
                let new5friends = Math.floor(newSubset / 5) - old5friends;
                if (new5friends > 0) {
                    let newEarnCoin = 60000 * new5friends;
                    newCoins = Number(newCoins + newEarnCoin);
                    newDisplayedTokens = Number(newDisplayedTokens + newEarnCoin);
                    setCoins(Number(newCoins));
                    setDisplayedTokens(newDisplayedTokens);
                    setReceivedData(prevData => ({...prevData, tokens: newDisplayedTokens}));
                    saveToLocalStorage('Qtvbv1v3', {
                        ...savedData,
                        tokens: newCoins,
                        invited_friends: Math.floor(old5friends + new5friends)
                    });
                    sendCoinDisplayData(newCoins);
                    setHasReceivedyoutubeReward(true);
                    createCoinRain();
                    setSuccessMessage("You have successfully checked the Friends Invite. +" + newEarnCoin + " coins added!");
                    setShowSuccessModal(true);

                    const postData = {
                        telegram_id: savedData.telegram_id,
                        invited_friends: Math.floor(old5friends + new5friends)
                    };
                    await saveAndGetUserData(apiUrl, postData);
                    setHasFriendsinviteReward(0);
                }
            }
        } catch (error) {
            setErrorMessage("An error occurred while checking Friends Invite status.");
            setShowErrorModal(true);
        }
    };

    const handleCheckMembership = async () => {
        try {
            const response = await fetch(`https://api.telegram.org/bot7263359274:AAGXSWt0EZPwm1LyBtTbf2ENzcP1D6O_PgQ/getChatMember`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    chat_id: '@netcoincapital_community',
                    user_id: user.id
                }),
            });

            const result = await response.json();

            if (result.ok && result.result.status !== 'left' && result.result.status !== 'kicked') {

                let newCoins = Number(coins);
                let newDisplayedTokens = Number(displayedTokens);
                newCoins = Number(newCoins + 50000);
                newDisplayedTokens = Number(newDisplayedTokens + 50000);
                setCoins(Number(newCoins));
                setDisplayedTokens(newDisplayedTokens);
                setReceivedData(prevData => ({...prevData, tokens: newDisplayedTokens}));

                // let coins=Number(newCoins);
                const savedData = loadFromLocalStorage('Qtvbv1v3') || {};
                saveToLocalStorage('Qtvbv1v3', {
                    ...savedData,
                    joinTelegramGroup: 1,
                    tokens: newCoins
                });
                sendCoinDisplayData(newCoins);

                setHasReceivedTelegramReward(true);
                createCoinRain(); // Run coin rain animation
                setJoinedGroupAt(new Date());
                setSuccessMessage("You have successfully joined the Telegram group. +50,000 coins added!");
                setShowSuccessModal(true);

                const postData = {
                    telegram_id: savedData.telegram_id,
                    join_telegram_group: 1
                };
                await saveAndGetUserData(apiUrl, postData);
                setJoinedToGroup(1);
            } else {
                setErrorMessage("You are not a member of the Telegram group yet.");
                setShowErrorModal(true);
            }
        } catch (error) {
            setErrorMessage("An error occurred while checking membership status." + error);
            setShowErrorModal(true);
        }
    };


    const handleCheckMembership2 = async () => {
        try {
            const response = await fetch(`https://api.telegram.org/bot7263359274:AAGXSWt0EZPwm1LyBtTbf2ENzcP1D6O_PgQ/getChatMember`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    chat_id: '@Netcoincapital_ncc',
                    user_id: user.id
                }),
            });

            const result = await response.json();
            if (result.ok && result.result.status !== 'left' && result.result.status !== 'kicked') {

                let newCoins = Number(coins);
                let newDisplayedTokens = Number(displayedTokens);
                newCoins = Number(newCoins + 50000);
                newDisplayedTokens = Number(newDisplayedTokens + 50000);
                setCoins(Number(newCoins));
                setDisplayedTokens(newDisplayedTokens);
                setReceivedData(prevData => ({...prevData, tokens: newDisplayedTokens}));
                const savedData = loadFromLocalStorage('Qtvbv1v3') || {};
                saveToLocalStorage('Qtvbv1v3', {
                    ...savedData,
                    joinTelegramChannel:1,
                    tokens: newCoins
                });
                sendCoinDisplayData(newCoins);

                setHasReceivedTelegramReward2(true);
                createCoinRain();
                setJoinedChannelAt(new Date());
                setSuccessMessage("You have successfully joined the Telegram Channel. +50,000 coins added!");
                setShowSuccessModal(true);
                const postData = {
                    telegram_id: savedData.telegram_id,
                    join_telegram_channel: 1
                };
                await saveAndGetUserData(apiUrl, postData);
                setJoinedToChannel(1);

            } else {
                setErrorMessage("You are not a member of the Telegram group yet.");
                setShowErrorModal(true);
            }
        } catch (error) {
            setErrorMessage("An error occurred while checking membership status.");
            setShowErrorModal(true);
        }
    };

// Function to show an alert message
    const showAlert = (message) => {
        setAlertMessage(message);
        setIsAlertModalOpen(true);
    };

// Function to close the alert modal
    const closeAlertModal = () => {
        setIsAlertModalOpen(false);
        setAlertMessage('');
    };

// Function to handle Instagram membership check
    const handleCheckInstagramMembership = async () => {
        if (!hasClickedInstagramJoinButton) {
            setSuccessMessage("You need to follow the Instagram first.");
            setShowSuccessModal(true);
            return;
        }
        if (!joinedToInstagram)
            try {

                let newCoins = Number(coins);
                let newDisplayedTokens = Number(displayedTokens);
                newCoins = Number(newCoins + 50000);
                newDisplayedTokens = Number(newDisplayedTokens + 50000);
                setCoins(Number(newCoins));
                setDisplayedTokens(newDisplayedTokens);
                setReceivedData(prevData => ({...prevData, tokens: newDisplayedTokens}));
                const savedData = loadFromLocalStorage('Qtvbv1v3') || {};
                saveToLocalStorage('Qtvbv1v3', {
                    ...savedData,
                    joinedToInstagram: 1,
                    tokens: newCoins
                });
                sendCoinDisplayData(newCoins);
                setHasReceivedInstagramReward(true);
                createCoinRain();
                setSuccessMessage("You have successfully checked the Instagram info. +50,000 coins added!");
                setShowSuccessModal(true);

                const postData = {
                    telegram_id: savedData.telegram_id,
                    join_instagram: 1
                };
                await saveAndGetUserData(apiUrl, postData);
                setJoinedToInstagram(1);

            } catch (error) {
                setErrorMessage("An error occurred while checking Instagram membership status.");
                setShowErrorModal(true);
            }
    };

// Function to handle X (Twitter) membership check
    const handleCheckXMembership = async () => {
        if (!hasClickedXJoinButton) {
            setSuccessMessage("You need to follow the X account first.");
            setShowSuccessModal(true);
            return;
        }

        if (!joinedToX)
            try {

                let newCoins = Number(coins);
                let newDisplayedTokens = Number(displayedTokens);
                newCoins = Number(newCoins + 50000);
                newDisplayedTokens = Number(newDisplayedTokens + 50000);
                setCoins(Number(newCoins));
                setDisplayedTokens(newDisplayedTokens);
                setReceivedData(prevData => ({...prevData, tokens: newDisplayedTokens}));
                const savedData = loadFromLocalStorage('Qtvbv1v3') || {};
                saveToLocalStorage('Qtvbv1v3', {
                    ...savedData,
                    joinedToX: 1,
                    tokens: newCoins
                });
                sendCoinDisplayData(newCoins);

                setHasReceivedXReward(true);
                createCoinRain();
                setSuccessMessage("You have successfully followed the X account. +50,000 coins added!");
                setShowSuccessModal(true);

                const postData = {
                    telegram_id: savedData.telegram_id,
                    join_x: 1
                };
                await saveAndGetUserData(apiUrl, postData);
                setJoinedToX(1);

            } catch (error) {
                // alert("Error checking membership status:" + error);
                setErrorMessage("An error occurred while checking X membership status.");
                setShowErrorModal(true);
            }
    };

// Function to close the error modal
    const closeErrorModal = () => {
        setShowErrorModal(false);
    };

// // Function to handle boost click
//     const handleBoostClick = () => {
//         if (boostAvailable > 0) {
//             setBoostAvailable(boostAvailable - 1);
//             setBoostCooldown(60); // Set cooldown to 60 minutes
//             setBoostLastUsed(new Date());
//
//             const boostCooldownTimer = setInterval(() => {
//                 setBoostCooldown(prevCooldown => {
//                     if (prevCooldown <= 1) {
//                         clearInterval(boostCooldownTimer);
//                         return 0;
//                     }
//                     return prevCooldown - 1; // Added the missing semicolon here
//                 });
//             }, 60000); // Runs every 60 seconds
//         }
//     };

// Function to save settings
    const handleSaveSettings = () => {
        // Save settings
        localStorage.setItem('vibrationIntensity', vibrationIntensity);

        // Close modal
        toggleSettingsModal();
    };

// Function to handle full energy click
    const handleFullEnergyClick = () => {
        if (chargeValue >= maxChargeValue) {
            setSuccessMessage("Your energy is full");
            setShowSuccessModal(true);
            return;
        }
        if (!isFullEnergyDisabled) { // Ensure the button is enabled
            setIsConfirmModalOpen(true);
        }
    };

// Function to confirm full energy
    const confirmFullEnergy = () => {
        const savedData = loadFromLocalStorage('Qtvbv1v3') || {};
        if (boostAvailable > 0 && !isFullEnergyDisabled) {
            setChargeValue(maxChargeValue);
            localStorage.setItem('userSetting', JSON.stringify({chargevalue: chargeValue}));

            setBoostAvailable(boostAvailable - 1);
            const postData = {
                telegram_id: savedData.telegram_id,
                full_energy: Date.now(),
                boost_full_energy: Number(boostAvailable) - 1
            };
            saveAndGetUserData(apiUrl, postData);
            setIsFullEnergyDisabled(true);
            setIsTimerRunning(true); // Start the timer
            setCountdown(3600); // Reset countdown to 1 hour
            setIsConfirmModalOpen(false); // Close modal
            setIsBoostModalOpen(false); // Close modal
        }
        if (boostAvailable == 0) {
            checkAnyDayFullEnergy();
        }
    };

// Function to handle multitap click
    const handleMultitapClick = () => {

        const cost = 1000 * Math.pow(2, Number(multitapLevel)); // Cost for each level

        if (coins >= cost) {
            let nwcoins = Number(coins - cost);

            setCoins(nwcoins);
            setDisplayedTokens(nwcoins);


            const savedData = loadFromLocalStorage('Qtvbv1v3') || {};
            let newearn = Number(savedData.level_user) + Number(savedData.multi_tap_level);
            let newmulti = Number(savedData.multi_tap_level) + 1;


            saveToLocalStorage('Qtvbv1v3', {
                ...savedData,
                tokens: nwcoins,
                earn_per_tap: Number(newearn),
                multi_tap_level: Number(newmulti)
            });
            sendCoinDisplayData(nwcoins);
            setMultitapLevel(Number(multitapLevel) + 1); // Increase multitap level
            setReceivedData(prevData => ({...prevData, tokens: nwcoins}));
            const postData = {
                telegram_id: savedData.telegram_id,
                earn_per_tap: Number(newearn),
                multi_tap_level: Number(newmulti)
            };
            saveAndGetUserData(apiUrl, postData);

        }
    };

// Function to format coins
    const formatCoins = (coins) => {
        if (coins === null || coins === undefined) {
            return '0';
        }
        if (coins >= 1000000000) {
            return (coins / 1000000000).toFixed(1) + 'B';
        } else if (coins >= 1000000) {
            return (coins / 1000000).toFixed(1) + 'M';
        } else if (coins >= 10000) {
            return (coins / 1000).toFixed(1) + 'K';
        } else {
            return coins.toString();
        }
    };

// Function to format time
    const formatTime = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    };

    const formatTime2 = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = Math.floor(seconds % 60);
        return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    };







    //temporary //temporary //temporary //temporary //temporary //temporary //temporary //temporary //temporary //temporary
    //temporary //temporary //temporary //temporary //temporary //temporary //temporary //temporary //temporary //temporary


    const formatNumber = (number) => {
        return number.toLocaleString();
    };

    const truncate = (str, n) => {
        return str.length > n ? str.substr(0, n - 1) + '...' : str;
    };


    const displaySubsets = invitedUsers.length === 0 ? 0 : invitedUsers.length;


    useEffect(() => {
        if (joinedToYoutube || joinedToGroup || joinedToChannel || joinedToInstagram || joinedToX) {
            setShowRedDot(true);
        } else {
            setShowRedDot(false);
        }
    }, [joinedToYoutube, joinedToGroup, joinedToChannel, joinedToInstagram, joinedToX]);

    //temporary //temporary //temporary //temporary //temporary //temporary //temporary //temporary //temporary //temporary
    //temporary //temporary //temporary //temporary //temporary //temporary //temporary //temporary //temporary //temporary


    // turbo // turbo // turbo // turbo // turbo // turbo // turbo // turbo // turbo // turbo // turbo // turbo // turbo
    // turbo // turbo // turbo // turbo // turbo // turbo // turbo // turbo // turbo // turbo // turbo // turbo // turbo



    const handleTurboClick = async () => {
        const currentTime = Date.now();
        const lastTurboTime = JSON.parse(localStorage.getItem('turboTime'));

        // بررسی اینکه آیا 24 ساعت از آخرین فعال‌سازی Turbo گذشته است یا خیر
        if (lastTurboTime && (currentTime - lastTurboTime.time) < 24 * 60 * 60 * 1000) {
            alert("You can only use Turbo once every 24 hours.");
            return;
        }

        // فعال‌سازی Turbo
        setIsTurboActive(true);
        setIsTurboDisabled(true);

        // تغییر تصویر به turbolion و فعال کردن انیمیشن
        setCurrentImage(process.env.PUBLIC_URL + '/turbolion.webp');
        setIsTurbolion(true);

        // ذخیره زمان فعلی در لوکال استوریج برای 24 ساعت
        localStorage.setItem('turboTime', JSON.stringify({ time: currentTime }));

        // دریافت مقدار فعلی coins از localStorage
        const savedData = JSON.parse(localStorage.getItem('Qtvbv1v3')) || {};
        let currentCoins = Number(savedData.tokens) || 0;

        const tapValue = Number(level) + Number(multitapLevel) + 1 + earnPerClickBonus;

        // محاسبه مقدار جدید coins
        const newCoins = currentCoins + tapValue; // مقدار جدیدی که باید اضافه شود

        // به‌روزرسانی مقدار coins در state
        setCoins(newCoins);
        setDisplayedTokens(newCoins);

        // ذخیره مقدار جدید coins در localStorage
        localStorage.setItem('Qtvbv1v3', JSON.stringify({
            ...savedData,
            tokens: newCoins
        }));

        // ارسال مقدار جدید coins به دیتابیس
        try {
            const response = await axios.post(apiUrl + 'updateTokens', {
                telegram_id: savedData.telegram_id,
                tokens: newCoins
            });
            console.log('Response from server:', response.data);
        } catch (error) {
            console.error('Error updating tokens:', error);
        }

        // شروع شمارش معکوس 30 ثانیه‌ای
        setTurboCountdown(30);
        const countdownInterval = setInterval(() => {
            setTurboCountdown((prevCountdown) => {
                if (prevCountdown <= 1) {
                    clearInterval(countdownInterval);
                    setIsTurboActive(false);
                    setIsInfiniteCharge(false);
                    setEarnPerClickBonus(0);
                    setIsTurboDisabled(true);  // دکمه را تا 1 دقیقه غیرفعال می‌کند

                    // بازگرداندن تصویر به تصویر قبلی پس از 30 ثانیه و غیرفعال کردن انیمیشن
                    setCurrentImage(getkingboxImage(level));
                    setIsTurbolion(false);

                    // فعال کردن دکمه بعد از 1 دقیقه (اختیاری)
                    setTimeout(() => {
                        setIsTurboDisabled(false);
                    }, 60000); // 1 دقیقه

                    return 0;
                }
                return prevCountdown - 1;
            });
        }, 1000);

        // بستن تمامی مودال‌های باز
        closeAllModals();

        // تنظیم مقادیر مربوط به Charge و Earn
        const previousChargeValue = chargeValue;
        const previousMaxChargeValue = maxChargeValue;

        setChargeValue(1000000);
        setMaxChargeValue(1000000);

        setEarnPerClickBonus(40);

        setIsInfiniteCharge(true);
        setTimeout(() => {
            setIsInfiniteCharge(false);
            setChargeValue(previousChargeValue);
            setMaxChargeValue(previousMaxChargeValue);
        }, 30000);
    };

    useEffect(() => {
        if (!isTurboActive) {
            setCurrentImage(getkingboxImage(level)); // Pass the current level to the function
        }
    }, [isTurboActive, level]);

    const formatNumber1 = (number) => {
        return number < 10 ? `0${number}` : number.toString();
    };





    useEffect(() => {
        const savedTurboTime = JSON.parse(localStorage.getItem('turboTime'));
        const currentTime = Date.now();

        if (savedTurboTime && (currentTime - savedTurboTime.time) < 24 * 60 * 60 * 1000) {
            const timeLeft = 24 * 60 * 60 * 1000 - (currentTime - savedTurboTime.time);
            setIsTurboDisabled(true);

            // تنظیم زمان باقی‌مانده در UI
            const initialHours = Math.floor(timeLeft / (1000 * 60 * 60));
            const initialMinutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
            const initialSeconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
            setRemainingTime(`${String(initialHours).padStart(2, '0')}:${String(initialMinutes).padStart(2, '0')}:${String(initialSeconds).padStart(2, '0')}`);

            // به‌روزرسانی زمان باقی‌مانده هر دقیقه
            const intervalId = setInterval(() => {
                const remainingTime = timeLeft - (Date.now() - currentTime);
                if (remainingTime > 0) {
                    const hours = Math.floor(remainingTime / (1000 * 60 * 60));
                    const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
                    const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
                    setRemainingTime(`${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`);
                } else {
                    setIsTurboDisabled(false);
                    setRemainingTime('');
                    localStorage.removeItem('turboTime');
                    clearInterval(intervalId);
                }
            }, 1000); // هر ثانیه به روز رسانی می شود

            // غیر فعال کردن دکمه تا پایان زمان
            setTimeout(() => {
                setIsTurboDisabled(false);
                localStorage.removeItem('turboTime');
            }, timeLeft);

            return () => clearInterval(intervalId);
        } else {
            localStorage.removeItem('turboTime');
        }
    }, []);


    useEffect(() => {
        if (clawMarks.length > 0) {
            const timeout = setTimeout(() => {
                setClawMarks(prevMarks => prevMarks.slice(1));
            }, 1000);

            return () => clearTimeout(timeout);
        }
    }, [clawMarks]);

    // turbo // turbo // turbo // turbo // turbo // turbo // turbo // turbo // turbo // turbo // turbo // turbo // turbo
    // turbo // turbo // turbo // turbo // turbo // turbo // turbo // turbo // turbo // turbo // turbo // turbo // turbo






    //Render the component
        if (!isMobile) {
            return (
                <div className="qr-container">
                    <h1>Play on your mobile</h1>
                    <img src={qrbotIcon} alt="QR Code"/>
                    <p style={{fontWeight: "700"}}>@Ncc_Lions_Army_bot</p>
                    <p>tdesktop 7.4</p>
                </div>
            );
        }



    return (
        <div className="app">
            <div id="top-touch-area" className="top-touch-area disabled" ref={topTouchAreaRef}></div>
            {isPreLoading && (
                <div className="preloader">
                    <div id="top-touch-area" className="top-touch-area" ref={topTouchAreaRef}></div>
                    <img src={loadingImage} alt="Loading" className="loader"/>
                    <div className="preloader-content">
                        <span className="pre-con-text">Already Launched</span>
                        <span className="pre-con-text1">On Tron</span>
                        <span className="pre-con-text">Stay Tuned</span>
                        <div className="social-icons">
                            <a href="https://t.me/netcoincapital_community"><img src={telegramIcon}
                                                                                 alt="Telegram Icon"/></a>
                            <a href="https://www.instagram.com/netcoincapitalofficial/"><img src={instagramIcon}
                                                                                             alt="Instagram Icon"/></a>
                            <a href="https://twitter.com/ncctoken"><img src={xIcon} alt="X Icon"/></a>
                        </div>
                    </div>
                </div>
            )}
            <div className="header">
                <div className="header-left">
                    <span>Hello, {user.first_name}</span>
                </div>
                <div className="header-right">
                    <button onClick={toggleModal} className="modal-button">
                        <img src={modalIcon} alt="Modal Icon" className="icon1"/>
                    </button>
                </div>
            </div>
            <div className="ncc-container">
                {isTurboActive && (
                    <div className="countdown-timer-turbo oswald-countdown">
                       {formatNumber1(turboCountdown)}
                    </div>
                )}
                <div className="info-container">
                    <div className="info-box">
                        <span className="box-test1">Earn per tap</span>
                        <span className={`value ${isInfiniteCharge ? 'fire-effect' : ''}`}>
                            <img src={coinLargeIcon} alt="Coin Icon" className="icon"/>+{Number(level) + Number(multitapLevel) + 1 + earnPerClickBonus}
                        </span>
                    </div>

                    <div className="info-box">
                        <span className="box-test1">Coins to level up</span>
                        <span className="value">{formatCoins(coinsToLevelUpArray[level] || 0)}</span>
                    </div>
                    <div className="info-box">
                        <span className="box-test1">Activity</span>
                        <span className="value">
                            <img src={clockIcon} alt="Clock Icon" className="icon"/> {formatTime(totalActiveTime)} HR
                        </span>
                    </div>
                </div>
                {!isTurboActive && (
                    <>
                        <div className="coin-display">
                            <img src={coinLargeIcon} alt="Coin Icon" className="coin-icon"/>
                            <span>{formatNumber(coins)}</span>
                        </div>

                        <div className="level-display">
                            <span>coming soon</span>
                            <span><span style={{fontSize: "12px", color: "rgba(192,192,192,0.6)"}}> Level </span>{level}/10</span>
                        </div>

                        <div className="progress-bar">
                            <div className="progress" style={{width: `${progress}%`}}></div>
                        </div>
                    </>
                )}

                <div className="Army-container enable-tap" onClick={handleClick} onTouchStart={handleClick} onTouchEnd={e => e.preventDefault()}>
                    <div className="image-container22">
                        <div ref={animationContainer} className={`animation-container background-image ${animate ? 'scale-animation' : ''}`}></div>
                        <img
                            src={currentImage}
                            alt="kingbox"
                            className={`Army ${isTurbolion ? 'fly-animation' : ''}`}
                        />
                        {isTurboActive && clawMarks.map(mark => (
                            <img
                                key={mark.id}
                                src={claw} // مسیر به تصویر جای چنگ شیر
                                alt="Claw Mark"
                                className={`claw-mark ${mark.fadeOut ? 'fade-out' : ''}`}
                                style={{ top: mark.y, left: mark.x, position: 'absolute', zIndex: 999 }}
                            />
                        ))}

                    </div>
                    {clicks.map(click => (
                        <span key={click.id} className="click animated" style={{left: click.x, top: click.y}}>
            +{click.value}
        </span>
                    ))}
                </div>

                <div className="actions-container">
                    <div className="charge">
                        <img src={chargeIcon} alt="Charge Icon" className="icon2"/>
                        <span className={isInfiniteCharge ? 'fire-effect' : ''}>
                            {isInfiniteCharge ? '∞' : formatCoins(chargeValue)} / {isInfiniteCharge ? '∞' : formatCoins(maxChargeValue)}
                        </span>
                    </div>
                    <button className="boost" onClick={toggleBoostModal}>
                        <img src={boostIcon} alt="Boost Icon" className="icon"/>
                        <span>Boost</span>
                    </button>
                </div>
            </div>

            {isGameModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-content1">
                        <h2 className="golden-glow">Daily Spin Bonus</h2>
                        <div className="wheel-container">
                            <div
                                className={`wheel ${spinning ? "spinning" : ""}`}
                                style={{ transform: `rotate(${angle}deg)` }}
                            >
                                {["0", "70,000", "1,000", "50", "100,000", "7,500", "18,000", "35,000", "250"].map((prize, index) => (
                                    <div
                                        key={index}
                                        className="prize"
                                        style={{
                                            transform: `rotate(${index * (360 / 9)}deg) skewY(0deg)`,
                                        }}
                                    >
                                        <span className="label1">{prize}</span>
                                    </div>
                                ))}
                            </div>
                            <div className="wheel-center">Spin</div>
                        </div>
                        {selectedPrize && <p className="Congratulations">Congratulations! You won: <span className="prize-amount">{selectedPrize} COIN</span></p>}
                        {isButtonVisible && (
                            <button className="spin-button" onClick={spinWheel} disabled={spinning}>
                                Spin the Wheel!
                            </button>
                        )}
                    </div>
                </div>
            )}

            {isModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={toggleModal}>x</span>
                        <label className="modal-content-label">You Can Be Early Investor</label>
                        <p style={{textAlign: "center"}}>As you know, the NCC token is currently being traded on the XT
                            exchange. If you want to be among the first to receive this token, you can purchase it from
                            the exchange right now.</p>
                        <img src={xtqrIcon} alt="QR Code" className="qr-style"/>
                        <a href="https://www.xt.com/en/accounts/register?ref=2FNBM6" target="_blank"
                           className="register-button">Register on XT</a>
                    </div>
                </div>
            )}
            {isBoostModalOpen && (
                <div className="boost-modal">
                    <div className="boost-modal-content">
                        <label className="label-class">Your balance</label>
                        <div className="coin-display">
                            <img src={coinLargeIcon} alt="Coin Icon" className="coin-icon"/>
                            <span>{formatCoins(coins)}</span>
                        </div>
                        <span className="close" onClick={toggleBoostModal}>x</span>
                        <h2 style={{marginLeft: "15px"}}>Free daily boosters</h2>
                        <div className={`boost-option ${isFullEnergyDisabled ? 'disabled' : ''}`}
                             onClick={!isFullEnergyDisabled ? handleFullEnergyClick : null}>
                            <div className="boost-info">
                                <img src={chargeIcon} alt="Full Energy Icon"/>
                                <div className="boost-text">
                                    <span>Full energy</span>
                                    <span className="boost-price">{boostAvailable}/6 available</span>
                                </div>
                                {(isFullEnergyDisabled && boostAvailable < 6) && (
                                    <span className="countdown-timer">{formatTime2(countdown)}</span>
                                )}
                            </div>
                        </div>
                        <div className={`boost-option ${isTurboDisabled ? 'disabled' : ''}`} onClick={!isTurboDisabled ? handleTurboClick : null}>
                            <div className="boost-info">
                                <img src={boostIcon} alt="Turbo Icon"/>
                                <div className="boost-text">
                                    <span>Turbo</span>
                                    <span className="boost-price">
                                        {isTurboDisabled ? `Available in ${remainingTime}` : 'Available'}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <h2 style={{marginLeft: "15px"}}>Boosters</h2>
                        <div className={`boost-option ${coins < 1000 * Math.pow(2, multitapLevel) ? 'disabled' : ''}`}
                             onClick={coins >= 1000 * Math.pow(2, multitapLevel) ? handleMultitapClickWithModal : null}>
                            <div className="boost-info">
                                <img src={motivateIcon} alt="Multitap Icon"/>
                                <div className="boost-text">
                                    <span className="multitap">Multitap</span>
                                    <div className="boost-price">
                                        <img src={coinLargeIcon} alt="Coin Icon"/>
                                        <span className="boosters-level">{1000 * Math.pow(2, multitapLevel)} </span><span> • {Number(multitapLevel) + 1} lvl</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="boost-option disabled">
                            <div className="boost-info">
                                <img src={enerrIcon} alt="Energy Limit Icon"/>
                                <div className="boost-text">
                                    <span className="multitap">Energy limit</span>
                                    <div className="boost-price">
                                        <img src={coinLargeIcon} alt="Coin Icon"/>
                                        <span className="boosters-level">coming soon</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {isMulconfModalOpen && (
                <div className="confirm-modal">
                    <div className="confirm-modal-content" ref={confirmModalRef}>
                        <img src={motivateIcon} alt="Full Energy Icon" style={{width: "50px", height: "50px"}}/>
                        <h1 style={{color: "white"}}>Multitap</h1>
                        <p className="centered-text">Are you sure you want to increase the multitap level?</p>
                        <div className="energy-modal">
                            <img src={coinLargeIcon} alt="Coin Icon" className="modal-icon"/>
                            <span className="free-style">{1000 * Math.pow(2, multitapLevel)}</span>
                        </div>
                        <button className="confirm-button" onClick={() => {
                            handleMultitapClick();
                            setIsMulconfModalOpen(false);
                        }}>Go ahead</button>
                    </div>
                </div>
            )}
            {isConfirmModalOpen && (
                <div className="confirm-modal">
                    <div className="confirm-modal-content" ref={confirmModalRef}>
                        <img src={chargeIcon} alt="Full Energy Icon" style={{width: "50px", height: "50px"}}/>
                        <h1 style={{color: "white"}}>Full energy</h1>
                        <p>Recharge your energy to the maximum and do another round of mining</p>
                        <div className="energy-modal">
                            <img src={coinLargeIcon} alt="Coin Icon" className="modal-icon"/>
                            <span className="free-style">Free</span>
                        </div>
                        <button onClick={confirmFullEnergy} className="confirm-button">Go ahead</button>
                    </div>
                </div>
            )}
            {isEarnModalOpen && (
                <div className="earn-modal">
                    <div className="earn-modal-content">
                        <span className="close" onClick={toggleEarnModal}>x</span>
                        <div className="modal-header">
                            <div className="coin-wrapper">
                                <img src={cashdeskIcon} alt="Coin" className="earn-modal-top-image"/>
                            </div>
                            <h2>Earn More Coins</h2>
                        </div>
                        <div className="modal-body">
                            <h3 className="task-daily-list ">Daily tasks</h3>
                            <div className="task-item disabled" onClick={toggleDailyRewardModal}>
                                <img src={tipsIcon} alt="Daily Reward" className="task-icon"/>
                                <div className="task-info">
                                    <span className="task-title">Daily reward</span>
                                    <span className="task-reward">+6,649,000</span>
                                </div>
                                <span className="task-status"><img src={check} style={{width: "24px", height: "24px"}}/></span>
                            </div>
                            <h3 className="task-daily-list ">Tasks list</h3>

                            <div className={(!joinedToYoutube) ? "task-item-youtube" : "task-item"} onClick={(!joinedToYoutube) ? toggleyoutubeModal : ''}>
                                <img src={youtube} alt="YouTube" className="task-icon"/>
                                <div className="task-info">
                                    <span className="task-title">Join our Youtube channel</span>
                                    <span className="task-reward">+75,000</span>
                                </div>
                                {(joinedToYoutube) ? <span className="task-status"><img src={check} style={{width: "24px", height: "24px"}}/></span> : ''}
                            </div>


                            <div className="task-item" onClick={(!joinedToGroup) ? toggleTelegramModal : ''}>
                                <img src={Tel1Icon} alt="YouTube" className="task-icon"/>
                                <div className="task-info">
                                    <span className="task-title">Join our telegram group</span>
                                    <span className="task-reward">+50,000</span>
                                </div>
                                {(joinedToGroup) ? <span className="task-status"><img src={check} style={{width: "24px", height: "24px"}}/></span> : ''}
                            </div>


                            <div className="task-item" onClick={(!joinedToChannel) ? toggleTelegramModal2 : ''}>
                                <img src={Tel2Icon} alt="Telegram" className="task-icon"/>
                                <div className="task-info">
                                    <span className="task-title">Join our TG channel</span>
                                    <span className="task-reward">+50,000</span>
                                </div>
                                {(joinedToChannel) ? <span className="task-status"><img src={check} style={{width: "24px", height: "24px"}}/></span> : ''}
                            </div>
                            <div className="task-item" onClick={(!joinedToInstagram) ? toggleInstagramModal : ''}>
                                <img src={instIcon} alt="Instagram" className="task-icon"/>
                                <div className="task-info">
                                    <span className="task-title">Join our Instagram</span>
                                    <span className="task-reward">+50,000</span>
                                </div>
                                {(joinedToInstagram) ? <span className="task-status"><img src={check} style={{width: "24px", height: "24px"}}/></span> : ''}
                            </div>
                            <div className="task-item" onClick={(!joinedToX) ? togglexModal : ''}>
                                <img src={x1Icon} alt="Twitter" className="task-icon"/>
                                <div className="task-info">
                                    <span className="task-title">Follow our X account</span>
                                    <span className="task-reward">+50,000</span>
                                </div>
                                {(joinedToX) ? <span className="task-status"><img src={check} style={{width: "24px", height: "24px"}}/></span> : ''}
                            </div>


                            {/*<div className={(hasFriendsinviteReward >= 0) ? "task-item-invitefriends" : "task-item"} onClick={(hasFriendsinviteReward >= 0) ? toggle5friendModal : ''}>*/}
                            <div className={"task-item-invitefriends"} onClick={toggle5friendModal}>
                                <img src={friends} alt="YouTube" className="task-icon"/>
                                <div className="task-info">
                                    <span className="task-title">Invite 5 friends and win coins</span>
                                    <span className="task-reward">+60,000</span>
                                </div>

                                {/*{(hasFriendsinviteReward >= 0) ? '' : <span className="task-status"><img src={check} style={{width: "24px", height: "24px"}}/></span> }*/}
                            </div>




                        </div>
                    </div>
                </div>
            )}
            {isDailyRewardModalOpen && (
                <div className="daily-reward-modal">
                    <div className="daily-reward-modal-content">
                        <span className="close" onClick={toggleDailyRewardModal}>x</span>
                        <img src={tipsIcon} alt="Daily Reward" className="reward-icon"
                             style={{width: "80px", height: "80px"}}/>
                        <h2>Daily Reward</h2>
                        <p>Accrue coins for logging into the game daily without skipping</p>
                        <div className="daily-reward-grid">
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(day => (
                                <div
                                    className={`reward-item ${day === currentDay ? 'active' : 'inactive'} ${day === 1 && claimedToday ? 'claimed' : ''}`}
                                    key={day}
                                >
                                    <span style={{
                                        marginBottom: "3px",
                                        fontWeight: "700",
                                        marginTop: "0"
                                    }}>Day {day}</span>
                                    <img src={smallIcon} alt={`Day ${day}`} className="reward-icon"/>
                                    <span
                                        style={{fontWeight: "700"}}>{day === 1 ? '500' : day === 2 ? '1K' : day === 3 ? '2.5K' : day === 4 ? '5K' : day === 5 ? '15K' : day === 6 ? '25K' : day === 7 ? '100K' : day === 8 ? '500K' : day === 9 ? '1M' : '5M'}</span>
                                </div>
                            ))}
                        </div>
                        <button className="claim-button" onClick={handleClaimReward}>Claim</button>
                    </div>
                </div>
            )}
            {isyoutubeModalOpen && (
                <div className="youtube-modal">
                    <div className="youtube-modal-content">
                        <span className="close" onClick={toggleyoutubeModal}>x</span>
                        <img src={youtube1} alt="Telegram Icon" className="youtube-icon"/>
                        <h1 className="join-tg-group">Join Youtube Channel</h1>
                        <button className="join-button" onClick={openyoutubeLink}>Join</button>
                        <span className="reward-info">
                          <img src={coinLargeIcon} alt="Coin Icon" className="telegram-modal-coin"/>
                          <span className="youtube-price">+75,000</span>
                        </span>
                        {!hasReceivedYotubemReward && (
                            <button className="check-button" onClick={handleCheckyoutubeMembership}>Check</button>
                        )}
                    </div>
                </div>
            )}
            {isTelegramModalOpen && (
                <div className="telegram-modal">
                    <div className="telegram-modal-content">
                        <span className="close" onClick={toggleTelegramModal}>x</span>
                        <img src={telegramIcon} alt="Telegram Icon" className="telegram-icon"/>
                        <h1 className="join-tg-group">Join our TG group</h1>
                        <button className="join-button" onClick={openTelegramLink}>Join</button>
                        <span className="reward-info">
                          <img src={coinLargeIcon} alt="Coin Icon" className="telegram-modal-coin"/>
                          <span className="telegram-price">+50,000</span>
                        </span>
                        {!hasReceivedTelegramReward && (
                            <button className="check-button" onClick={handleCheckMembership}>Check</button>
                        )}
                    </div>
                </div>
            )}
            {isTelegramModalOpen2 && (
                <div className="telegram-modal2">
                    <div className="telegram-modal-content2">
                        <span className="close" onClick={toggleTelegramModal2}>x</span>
                        <img src={Tel4Icon} alt="Telegram Icon" className="telegram-icon2"/>
                        <h1 className="join-tg-group2">Join our TG channel</h1>
                        <button className="join-button2" onClick={openTelegramLink2}>Join</button>
                        <span className="reward-info2">
                          <img src={coinLargeIcon} alt="Coin Icon" className="telegram-modal-coin2"/>
                          <span className="telegram-price2">+50,000</span>
                        </span>
                        {!hasReceivedTelegramReward2 && (
                            <button className="check-button" onClick={handleCheckMembership2}>Check</button>
                        )}
                    </div>
                </div>
            )}
            {isInstagramModalOpen && (
                <div className="instagram-modal">
                    <div className="instagram-modal-content">
                        <span className="close" onClick={toggleInstagramModal}>x</span>
                        <img src={instagramIcon} alt="Instagram Icon" className="instagram-icon"/>
                        <h1 className="join-instagram-group">Join our Instagram</h1>
                        <button className="join-button" onClick={openInstagramLink}>Join</button>
                        <span className="reward-info">
                          <img src={coinLargeIcon} alt="Coin Icon" className="instagram-modal-coin"/>
                          <span className="instagram-price">+50,000</span>
                        </span>
                        {!hasReceivedInstagramReward && (
                            <button className="check-button" onClick={handleCheckInstagramMembership}>Check</button>
                        )}
                    </div>
                </div>
            )}
            {isXModalOpen && (
                <div className="x-modal">
                    <div className="x-modal-content">
                        <span className="close" onClick={togglexModal}>x</span>
                        <img src={xIcon} alt="X Icon" className="x-icon"/>
                        <h1 className="join-x-group">Follow our X account</h1>
                        <button className="join-button" onClick={openXLink}>Join</button>
                        <span className="reward-info">
                          <img src={coinLargeIcon} alt="Coin Icon" className="x-modal-coin"/>
                          <span className="x-price">+50,000</span>
                        </span>
                        {!hasReceivedXReward && (
                            <button className="check-button" onClick={handleCheckXMembership}>Check</button>
                        )}
                    </div>
                </div>
            )}
            {isfriendsinviteModalOpen && (
                <div className="youtube-modal">
                    <div className="youtube-modal-content">
                        <span className="close" onClick={toggle5friendModal}>x</span>
                        <img src={friends} alt="Telegram Icon" className="youtube-icon"/>
                        <h1 className="join-tg-group">Invite 5 friend </h1>
                        <span className="reward-info">
                          <img src={coinLargeIcon} alt="Coin Icon" className="telegram-modal-coin"/>
                          <span className="youtube-price">+60,000</span>
                        </span>
                        {(hasFriendsinviteReward > 0) && (
                            <button className="check-button" onClick={handleCheckfriendsinviteMembership}>Check</button>
                        )}
                    </div>
                </div>
            )}

            {isMineModalOpen && (
                <div className="mine-modal">
                    <div className="mine-modal-content">
                        <span className="close" onClick={toggleMineModal}>x</span>
                        {/*<div className="mine-modal-body">*/}
                        {/*    <h1 className="start-mining ">Start Mining</h1>*/}
                        {/*    <div className="tabs">*/}
                        {/*        <button className={activeTab === 'PR&Team' ? 'active' : ''} onClick={() => handleTabChange('PR&Team')}>PR&Team</button>*/}
                        {/*        <button className={activeTab === 'Markets' ? 'active' : ''} onClick={() => handleTabChange('Markets')}>Markets</button>*/}
                        {/*        <button className={activeTab === 'Legal' ? 'active' : ''} onClick={() => handleTabChange('Legal')}>Legal</button>*/}
                        {/*        <button className={activeTab === 'Web3' ? 'active' : ''} onClick={() => handleTabChange('Web3')}>Web3</button>*/}
                        {/*        <button className={activeTab === 'Specials' ? 'active' : ''} onClick={() => handleTabChange('Specials')}>Specials</button>*/}
                        {/*    </div>*/}

                        {/*    <div className="cards-container">*/}
                        {/*        {activeTab === 'PR&Team' && (*/}
                        {/*            <div className="cards-grid">*/}
                        {/*                {renderCard("CEO", "+100", "1K", false, "")}*/}
                        {/*                {renderCard("Marketing", "+70", "1K", false, "")}*/}
                        {/*                {renderCard("IT Team", "+240", "2K", false, "")}*/}
                        {/*                {renderCard("Support Team", "+70", "750", false, "")}*/}
                        {/*                {renderCard("HamsterBook", "+70", "500", false, "")}*/}
                        {/*                {renderCard("HamsterTube", "+90", "HamsterBook lvl 5", true, "HamsterBook lvl 5")}*/}
                        {/*                {renderCard("X", "+80", "550", false, "")}*/}
                        {/*                {renderCard("Cointelegraph", "+40", "350", false, "")}*/}
                        {/*                {renderCard("HamsterGram", "+50", "HamsterTube lvl 8", true, "HamsterTube lvl 8")}*/}
                        {/*                {renderCard("TikTok", "+100", "HamsterGram lvl 3", true, "HamsterGram lvl 3")}*/}
                        {/*            </div>*/}
                        {/*        )}*/}
                        {/*        /!* Add content for other tabs here *!/*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <img src={comingsoon} className="coming-soon"/>
                    </div>
                </div>
            )}
            {isFriendModalOpen && (
                <div className="friend-modal">
                    <div className="friend-modal-content">
                        <span className="close" onClick={togglefriendModal} style={{marginTop: "30px"}}>x</span>
                        <div className="inv-fri-head">
                            <h1 style={{marginTop: "60px"}}>Invite friends!</h1>
                            <p style={{marginTop: "-15px"}}>You and your friend will receive bonuses</p>
                        </div>
                        {/*<div className="bonus-item">*/}
                        {/*    <img src={friIcon} alt="Gift Icon" className="bonus-icon"/>*/}
                        {/*    <div className="bonus-text">*/}
                        {/*        <p>Invite a friend </p>*/}
                        {/*        <div style={{marginTop: "8px"}}><span>+5,000</span> for you and your friend</div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<button className="show-table-button" onClick={() => setShowTable(!showTable)}>More bonuses*/}
                        {/*</button>*/}
                        {showTable && (
                            <table className="friend-table">
                                <tbody>
                                <tr className="friend-row">
                                    <td className="friend-rewards">
                                        <span><img src={coinLargeIcon} alt="Coin"/>+20,000</span>
                                        <span><img src={coinLargeIcon} alt="Coin"/>+25,000</span>
                                        <span><img src={coinLargeIcon} alt="Coin"/>+25,000</span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        )}
                        <div className="friend-list-container">
                            <div className="refresh">
                                <h3 className="friend-list-header">List of your friends <span style={{color: "white"}}>({displaySubsets})</span></h3>
                                <button className="refresh-btn" onClick={toggleRefreshfriend}
                                        disabled={visibleButtonCheck}><img src={refresh}
                                                                           style={{width: "24px", height: "24px"}}/>
                                </button>
                            </div>
                            {invitedUsers.length === 0 ? (
                                <p className="no-friends-text">You haven't invited anyone yet</p>
                            ) : (
                                <table className="invited-users-table">
                                    <thead className="invite-users-th">
                                    <tr className="invite-users-th">
                                        <th>Username</th>
                                        <th>Coins</th>
                                        <th>Percentage</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    {invitedUsers.map((user, index) => (
                                        <tr key={index}>
                                            <td style={{textAlign: "left"}}>
                                                <span style={{
                                                    fontSize: "14px",
                                                    fontWeight: "700"
                                                }}>{truncate(user.username || 'User', 8)}</span>
                                                <span style={{ color: "white" }}>({user.sum_friends})</span>
                                            </td>
                                            <td className="coin-display" style={{textAlign: "left"}}>
                                                <img src={coinLargeIcon} alt="Coin Icon" className="coin-icon" style={{width: "30px", height: "30px"}}/>
                                                <span style={{fontSize: "20px"}}>{formatCoins(user.tokens)}</span>
                                            </td>
                                            <td style={{textAlign: "right"}}>10%</td>
                                        </tr>
                                    ))}


                                    </tbody>
                                </table>
                            )}
                        </div>
                        <div className="modal-buttons">
                            <button className="invite-button" onClick={() => {
                                const message = `\nPlay with me, become General of Ncc Lions Army and get a token airdrop!\n💸 Start playing now to win many rewards `;
                                const telegramLink = `https://t.me/Ncc_Lions_Army_bot/?start=${referral}`;

                                // Copy link to clipboard
                                navigator.clipboard.writeText(telegramLink).then(() => {
                                    setSuccessMessage("Invite link copied to clipboard!");
                                    setShowSuccessModal(true);
                                });

                                window.open(`https://t.me/share/url?url=${telegramLink}&text=${encodeURIComponent(message)}`, '_blank');
                            }}>Invite a friend
                            </button>
                            <button className="other-button" onClick={handleCopyClick}><img src={copy} style={{width: "24px", height: "28px"}}/></button>
                        </div>
                    </div>
                </div>
            )}
            {isSettingsModalOpen && (
                <div className="settings-modal">
                    <div className="settings-modal-content">
                        <span className="close" onClick={toggleSettingsModal}>x</span>
                        <div className="modal-body1">
                            <h1>Settings</h1>
                            <div className="tabs">
                                <button onClick={() => handleTabChange('wallet')}
                                        className={activeTab === 'wallet' ? 'active' : ''}>User Information
                                </button>
                                <button onClick={() => handleTabChange('vibration')}
                                        className={activeTab === 'vibration' ? 'active' : ''}>Device Settings
                                </button>
                                <button onClick={() => handleTabChange('Aboutus')}
                                        className={activeTab === 'Aboutus' ? 'active' : ''}>About us
                                </button>
                            </div>
                            {activeTab === 'wallet' && (
                                <div className="tab-content">
                                    <div style={{marginBottom: "15px"}}>
                                        <span style={{fontWeight: "600"}}>Welcome dear </span><span
                                        style={{fontSize: "24px", fontWeight: "700"}}>{user.first_name || 'User'}</span>
                                    </div>
                                    <div className="settings-user-info-body">
                                        <p style={{paddingLeft: "20px"}}>Enter your email address:</p>
                                        <input
                                            type={userEmail ? "text" : "email"}
                                            value={userEmail || emailAddress}
                                            onChange={handleEmailChange}
                                            className="wallet-input"
                                            placeholder="Enter your email address"
                                            disabled={isEmailSubmitted}
                                        />
                                        <p style={{paddingLeft: "20px"}}>Enter your wallet address:</p>
                                        <input
                                            type={walletAddress ? "text" : "wallet address"}
                                            value={walletAddress}
                                            onChange={handleWalletAddressChange}
                                            className="wallet-input"
                                            placeholder="Enter your Tron wallet address"
                                            disabled={isWalletSubmitted}
                                        />
                                        <button onClick={handleSubmit} className="settings-button">Submit</button>
                                    </div>
                                </div>
                            )}
                            {activeTab === 'vibration' && (
                                <div className="tab-content">
                                    <div style={{marginBottom: "15px"}}>
                                        <span style={{fontWeight: "600"}}>Welcome dear </span><span
                                        style={{fontSize: "24px", fontWeight: "700"}}>{user.first_name || 'User'}</span>
                                    </div>
                                    <div className="settings-user-info-body">
                                        <p className="slider-text">Adjust the vibration intensity:</p>
                                        <input
                                            type="range"
                                            min="0"
                                            max="100"
                                            value={vibrationIntensity}
                                            onChange={handleVibrationChange}
                                            className="slider"
                                        />
                                        <span>{vibrationIntensity}</span>
                                        <button onClick={handleSaveSettings} className="settings-button">Save Settings
                                        </button>
                                    </div>
                                </div>
                            )}
                            {activeTab === 'Aboutus' && (
                                <div className="tab-content">

                                    <div className="settings-user-info-body">
                                        <span className="slider-text"><a href="https://netcoincapital.com">netcoincapital.com</a></span>
                                        <span><a href="https://ncclionsarmy.com">ncclionsarmy.com</a></span>
                                        <p className="slider-text">NetCoinCapital was established in 2020 and has been active ever since.
                                            The goal of NetCoinCapital is to create a dynamic community of citizens to form its own ecosystem.
                                            Now, the NCC Lion Army has been introduced to ensure that the token is completely out of the creators'
                                            control and is in the hands of the general public. The value of the token will be determined by supply
                                            and demand.

                                            Please note that NCC is already in the market, so: THIS IS A REAL MONEY<br/><br/></p>

                                    </div>
                                    <div className="footer">
                                        <span style={{fontSize: "18px", fontWeight: "600"}}>Created By Netcoincapital </span>
                                        <span>@All rights reserved 2024</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
            {showErrorModal && (
                <div className="error-modal">
                    <div className="error-modal-content">
                        <p className="error-text">{errorMessage}</p>
                        <button className="error-ok-button" onClick={closeErrorModal}>OK</button>
                    </div>
                </div>
            )}
            {showSuccessModal && (
                <div className="error-modal">
                    <div className="error-modal-content">
                        <p className="error-text">{successMessage}</p>
                        <button className="error-ok-button" onClick={() => setShowSuccessModal(false)}>OK</button>
                    </div>
                </div>
            )}
            <div className="mobile-menu">
                <div className="menu-item" onClick={closeAllModals}>
                    <img src={exchangeIcon} alt="Exchange Icon" className="menu-icon"/>
                    <span>Exchange</span>
                </div>
                <div className="menu-item" onClick={toggleMineModal}>
                    <img src={mineIcon} alt="Mine Icon" className="menu-icon"/>
                    <span>Mine</span>
                </div>
                <div className="menu-item" onClick={togglefriendModal}>
                    <img src={friendsIcon} alt="Friends Icon" className="menu-icon"/>
                    <span>Friends</span>
                </div>
                <div className="menu-item" onClick={toggleEarnModal}>
                    <img src={earnIcon} alt="Earn Icon" className="menu-icon"/>
                    <span>Earn</span>{showRedDot && <div className="red-dot"></div>}

                </div>

                <div className="menu-item" onClick={toggleSettingsModal}>
                    <img src={setIcon} alt="Settings Icon" className="menu-icon"/>
                    <span>Settings</span>
                </div>
            </div>

        </div>
    );
}

export default App;

